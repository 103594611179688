import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { currentCodeVersion } from "../../../appConstants"
import { Address } from "../../../dto/address"
import { BSProject } from "../../../dto/beem-shot/BSProject/BSProject"
import { BSProjectCreationDto } from "../../../dto/beem-shot/BSProject/BSProjectCreationDto"
import { BSProjectUpdateDto } from "../../../dto/beem-shot/BSProject/BSProjectUpdateDto"
import { BsProjectRoleEnum } from "../../../enum/bs-project-role-enum"
import { CodesVersion } from "../../../enum/codesVersionEnum"
import { ProjectStatusEnum } from "../../../enum/projectStatusEnum"
import { useBSProject } from "../../../hooks/beem-shot/useBSProject"
import { CodeReferenceContextProvider } from "../../code-acv/CodeReferenceContext"
import { OrganizationContext } from "../../organization/organization-context"

export interface IForm {
  id: string | undefined
  name: string
  businessCode: string
  licenceDate?: Date
  street: string
  city: string
  zipCode: string
  additional: string
  status: ProjectStatusEnum
  departmentId: string
}

export function formToDto(form: IForm): BSProjectCreationDto {
  return {
    name: form.name,
    businessCode: form.businessCode,
    licenceDate: form.licenceDate,
    address: {
      street: form.street?.trim(),
      city: form.city?.trim(),
      zipCode: form.zipCode?.trim(),
      additional: form.additional?.trim(),
    },
    departmentId: form.departmentId,
  }
}

export function updateFormToDto(bsProject: BSProject, form?: IForm): BSProjectUpdateDto {
  return form
    ? {
        id: bsProject.id ?? "",
        organizationId: bsProject.organizationId,
        name: form.name,
        businessCode: form.businessCode,
        licenceDate: form.licenceDate,
        address: {
          street: form.street,
          city: form.city,
          zipCode: form.zipCode,
          additional: form.additional,
        },
        codesVersion: bsProject.codesVersion,
        status: form.status,
        coverImgFileName: bsProject.coverImgFileName,
        coverImgFileType: bsProject.coverImgFileType,
        coverImgAwsFileKey: bsProject.coverImgFileName,
        departmentId: form.departmentId,
      }
    : {
        id: bsProject.id ?? "",
        organizationId: bsProject.organizationId,
        name: bsProject.name,
        businessCode: bsProject.businessCode,
        licenceDate: bsProject.licenceDate,
        address: {
          street: bsProject.address.street,
          city: bsProject.address.city,
          zipCode: bsProject.address.zipCode,
          additional: bsProject.address.additional,
        },
        codesVersion: bsProject.codesVersion,
        status: bsProject.status,
        coverImgFileName: bsProject.coverImgFileName,
        coverImgFileType: bsProject.coverImgFileType,
        coverImgAwsFileKey: bsProject.coverImgFileName,
        departmentId: bsProject.departmentId,
      }
}

export function dtoToForm(bsProject: BSProject | undefined): IForm {
  return bsProject
    ? {
        id: bsProject.id,
        name: bsProject.name,
        businessCode: bsProject.businessCode,
        licenceDate: bsProject.licenceDate,
        street: bsProject.address.street,
        city: bsProject.address.city,
        zipCode: bsProject.address.zipCode,
        additional: bsProject.address.additional,
        status: bsProject.status,
        departmentId: bsProject.departmentId,
      }
    : {
        id: "",
        name: "",
        businessCode: "",
        licenceDate: new Date(),
        street: "",
        city: "",
        zipCode: "",
        additional: "",
        status: ProjectStatusEnum.IN_PROGRESS,
        departmentId: "",
      }
}

export function dtoToBsProject(dto: BSProject): BSProject {
  return {
    id: dto.id,
    organizationId: dto.organizationId,
    name: dto.name || "",
    businessCode: dto.businessCode || "",
    licenceDate: dto.licenceDate,
    address: dto.address || new Address(),
    codesVersion: dto.codesVersion || currentCodeVersion,
    status: dto.status || ProjectStatusEnum.IN_PROGRESS,
    coverImgAwsFileKey: dto.coverImgAwsFileKey || "",
    coverImgFileName: dto.coverImgFileName || "",
    coverImgFileType: dto.coverImgFileType || "",
    createdByUser: dto.createdByUser || "",
    lastModifiedDate: dto.lastModifiedDate ? new Date(dto.lastModifiedDate) : undefined,
    role: dto.role,
    departmentId: dto.departmentId,
  }
}

export function isBSMainUser(bsProject: BSProject | undefined): boolean {
  return bsProject?.role ? bsProject.role === BsProjectRoleEnum.MAIN_USER : false
}

export function isBSBimModelOwnerUser(bsProject: BSProject | undefined): boolean {
  return bsProject?.role ? bsProject.role === BsProjectRoleEnum.BIM_MODEL_OWNER : false
}

export const BSProjectContext = React.createContext<BeemShotProjectStore>({} as BeemShotProjectStore)

export function BSProjectContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const openSuccessSnackbar = useContext(SuccessContext)
  const { refreshOrganization } = useContext(OrganizationContext)

  const { bsProjectId } = useParams()
  const { bsVariantId } = useParams()

  const { getBSProject, createBSProject, updateBSProject, getStartEstimating } = useBSProject()

  const [bsProject, setBsProject] = useState<BSProject | undefined>()

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const createProject = useCallback(
    (bsProjectCreationDto: BSProjectCreationDto) =>
      createBSProject(bsProjectCreationDto).then((newBsProject) => {
        setBsProject(newBsProject)
        return newBsProject
      }),
    [createBSProject]
  )

  const updateProject = useCallback(
    (bsProjectUpdateDto: BSProjectUpdateDto) => {
      if (bsProjectId && bsProjectId !== "new") {
        updateBSProject(bsProjectUpdateDto).then((updatedBsProject) => {
          setBsProject(updatedBsProject)
        })
      }
      return Promise.resolve()
    },
    [bsProjectId, updateBSProject]
  )

  const fetchBsProject = useCallback(
    (id: string): Promise<void> =>
      getBSProject(id).then((newBSProject) => {
        if (newBSProject) {
          setBsProject(dtoToBsProject(newBSProject))
        }
      }),
    [getBSProject]
  )

  const refreshProject = useCallback((): void => {
    if (bsProjectId) {
      getBSProject(bsProjectId).then((newBSProject) => {
        if (newBSProject) {
          setBsProject(dtoToBsProject(newBSProject))
        }
      })
    } else {
      setBsProject(undefined)
    }
  }, [bsProjectId, getBSProject])

  const startEstimating = useCallback(() => {
    if (bsVariantId) {
      return getStartEstimating(bsVariantId).then(() => undefined)
    } else {
      return Promise.resolve(undefined)
    }
  }, [getStartEstimating, bsVariantId])

  useEffect(() => {
    refreshProject()
  }, [refreshProject])

  const updateStatus = useCallback(
    (status: ProjectStatusEnum) => {
      if (bsProject?.id) {
        const bsProjectDto: BSProjectUpdateDto = updateFormToDto(bsProject)
        bsProjectDto.status = status

        return updateBSProject(bsProjectDto).then((updateToArchived) => {
          setBsProject(updateToArchived)
          openSuccessSnackbar("Statut du projet mis à jour à l'état Archivé")
        })
      }
      return Promise.resolve()
    },
    [bsProject, openSuccessSnackbar, updateBSProject]
  )

  const handleDeleteBsProject = useCallback(() => {
    if (bsProject?.id && !isDeleting) {
      setIsDeleting(true)
      return updateStatus(ProjectStatusEnum.DELETED)
        .then(() => refreshOrganization())
        .then(() => openSuccessSnackbar("Suppression du projet Beem Shot réalisé avec succès")) // Refresh organization to refresh the projects counter
        .finally(() => {
          setIsDeleting(false)
        })
    }
    return Promise.resolve()
  }, [bsProject?.id, isDeleting, openSuccessSnackbar, refreshOrganization, updateStatus])

  const bsProjectStore = useMemo(
    () => ({
      bsProject,
      setBsProject,
      createProject,
      updateProject,

      startEstimating,
      refreshProject,
      fetchBsProject,
      handleDeleteBsProject,
    }),
    [bsProject, createProject, updateProject, startEstimating, refreshProject, fetchBsProject, handleDeleteBsProject]
  )

  return (
    <BSProjectContext.Provider value={bsProjectStore}>
      <CodeReferenceContextProvider projectCodeVersion={bsProject?.codesVersion || CodesVersion.VERSION_1_6}>
        {children}
      </CodeReferenceContextProvider>
    </BSProjectContext.Provider>
  )
}

export type BeemShotProjectStore = {
  bsProject: BSProject | undefined
  setBsProject: Dispatch<SetStateAction<BSProject | undefined>>
  createProject(beemShotProjectCreationDto: BSProjectCreationDto): Promise<BSProject | void>
  updateProject(bsProjectUpdateDto: BSProjectUpdateDto): Promise<BSProject | void>
  startEstimating(): Promise<undefined>
  refreshProject(): void
  fetchBsProject(bsProjectId: string): Promise<void>
  handleDeleteBsProject(): Promise<void>
}
