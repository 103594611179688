import { Outlet, Route } from "react-router-dom"
import { BSLayout } from "../components/layout/BSLayout"
import RequireAuth from "../components/redirect/require-auth"
import { pagesUrl } from "../core/appConstants"
import BsModelContextProvider from "../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSModelFileContextProvider } from "../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSImportContextProvider } from "../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import BSModelInvitationContextProvider from "../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"
import { BSCategoriesContextProvider } from "../core/context/beem-shot/BSCategory/BSCategoriesContext"
import { BSInputContextProvider } from "../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSItemContextProvider } from "../core/context/beem-shot/BSItems/BSItemContext"
import { BSMaterialContextProvider } from "../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { BSProjectContextProvider } from "../core/context/beem-shot/BSProject/BSProjectContext"
import { BSProjectListContextProvider } from "../core/context/beem-shot/BSProject/BSProjectListContext"
import { BSVariantContextProvider } from "../core/context/beem-shot/BSVariant/BSVariantContext"
import { CodeReferenceContextProvider } from "../core/context/code-acv/CodeReferenceContext"
import { DepartmentContextProvider } from "../core/context/departmentContext"
import { LotContextProvider } from "../core/context/lot-context"
import { NavBarContextProvider } from "../core/context/nav-bar/AppNavBarContext"
import DomainNameValidatorContextProvider from "../core/context/organization/domain-name-validator-context"
import { OrganizationContextProvider } from "../core/context/organization/organization-context"
import { SideBarContextProvider } from "../core/context/side-bar/side-bar-context"
import { CodesVersion } from "../core/enum/codesVersionEnum"
import { AccountPage } from "../pages/account-page/account-page"
import { BSBimModelControl } from "../pages/beem-shot/BSBimModelPage/BSBimModelControl"
import { BSInvitationConfirmationPage } from "../pages/beem-shot/BSBimModelPage/BSInvitationConfirmationPage"
import { BSUploadPage } from "../pages/beem-shot/BSBimModelPage/BSUploadPage"
import { BSCalculationPage } from "../pages/beem-shot/BSCalculationPage/BSCalculationPage"
import { BSCreateCalculationPage } from "../pages/beem-shot/BSCalculationPage/BSCreateCalculationPage"
import { BSHomePage } from "../pages/beem-shot/BSHomePage"
import { BSProjectDetailsPage } from "../pages/beem-shot/BSProjectDetailPage/BSProjectDetailsPage"
import { BeemShotVariantDetailPage } from "../pages/beem-shot/BSVariantDetailsPage/BSVariantDetailsPage"
import { MyOrganization } from "../pages/my-organization-page/my-organization"
import { MyOfferPage } from "../pages/offer/MyOfferPage"
import { ConfigurationHelperPage } from "../pages/support/ConfigurationHelperPage"
import { HelperCenterPage } from "../pages/support/HelpCenterPage"
import { TutorialHelperPage } from "../pages/support/TutorialHelperPage"

export const BSRoutes = (
  <Route
    element={
      <RequireAuth>
        <OrganizationContextProvider>
          <BSProjectContextProvider>
            <BSVariantContextProvider>
              <SideBarContextProvider>
                <NavBarContextProvider>
                  <DomainNameValidatorContextProvider>
                    <BSLayout />
                  </DomainNameValidatorContextProvider>
                </NavBarContextProvider>
              </SideBarContextProvider>
            </BSVariantContextProvider>
          </BSProjectContextProvider>
        </OrganizationContextProvider>
      </RequireAuth>
    }>
    {/* User account */}
    <Route path={pagesUrl.ACCOUNT_PAGE} element={<AccountPage />} />
    <Route path={pagesUrl.MY_OFFER_PAGE} element={<MyOfferPage />} />
    <Route
      path={pagesUrl.MY_ORGANIZATION_PAGE}
      element={
        <DomainNameValidatorContextProvider>
          <MyOrganization />
        </DomainNameValidatorContextProvider>
      }
    />
    <Route path={pagesUrl.BEEM_SHOT_HELP_CENTER} element={<HelperCenterPage />} />
    <Route path={pagesUrl.BEEM_SHOT_CONFIGURATION_HELPER} element={<ConfigurationHelperPage />} />
    <Route
      path={pagesUrl.BEEM_SHOT_TUTORIAL_HELPER}
      element={
        <BSCategoriesContextProvider>
          <CodeReferenceContextProvider projectCodeVersion={CodesVersion.VERSION_1_6}>
            <TutorialHelperPage />
          </CodeReferenceContextProvider>
        </BSCategoriesContextProvider>
      }
    />

    <Route path={pagesUrl.MY_OFFER_PAGE} element={<MyOfferPage />} />
    {/* Beem Shot main pages */}
    <Route
      element={
        <DepartmentContextProvider>
          <LotContextProvider>
            <BSProjectListContextProvider>
              <DomainNameValidatorContextProvider>
                <Outlet />
              </DomainNameValidatorContextProvider>
            </BSProjectListContextProvider>
          </LotContextProvider>
        </DepartmentContextProvider>
      }>
      <Route
        element={
          <BsModelContextProvider>
            <BSInputContextProvider>
              <Outlet />
            </BSInputContextProvider>
          </BsModelContextProvider>
        }>
        <Route path={pagesUrl.BEEM_SHOT_PROJECTS_DETAIL} element={<BSProjectDetailsPage />} />
        <Route
          path={pagesUrl.BEEM_SHOT_VARIANTS_DETAIL}
          element={
            <BSMaterialContextProvider>
              <BSItemContextProvider>
                <BSCategoriesContextProvider>
                  <BeemShotVariantDetailPage />
                </BSCategoriesContextProvider>
              </BSItemContextProvider>
            </BSMaterialContextProvider>
          }
        />
        <Route
          element={
            <BSModelFileContextProvider>
              <BSItemContextProvider>
                <BSModelInvitationContextProvider>
                  <BSImportContextProvider>
                    <BSCategoriesContextProvider>
                      <Outlet />
                    </BSCategoriesContextProvider>
                  </BSImportContextProvider>
                </BSModelInvitationContextProvider>
              </BSItemContextProvider>
            </BSModelFileContextProvider>
          }>
          <Route path={pagesUrl.BEEM_SHOT_CALCUL} element={<BSCalculationPage />} />
          <Route path={pagesUrl.BEEM_SHOT_UPLOAD_PAGE} element={<BSUploadPage />} />
          <Route path={pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION} element={<BSInvitationConfirmationPage />} />
          <Route path={pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL} element={<BSBimModelControl />} />
          <Route path={pagesUrl.BEEM_SHOT_CREATE_CALCUL} element={<BSCreateCalculationPage />} />
        </Route>
        <Route path={pagesUrl.BEEM_SHOT_HOME} element={<BSHomePage />} />
      </Route>
    </Route>
  </Route>
)
