export enum OriginRecordStatus {
  EMPTY = "EMPTY",
  UP_TO_DATE = "UP_TO_DATE",
  OUTDATED = "OUTDATED",
  DELETED = "DELETED",
  UNKNOWN = "UNKNOWN",
}

export function originRecordStatusToLabel(status: OriginRecordStatus): string {
  switch (status) {
    case OriginRecordStatus.EMPTY:
      return ""
    case OriginRecordStatus.UP_TO_DATE:
      return "A jour"
    case OriginRecordStatus.OUTDATED:
      return "A mettre à jour"
    case OriginRecordStatus.DELETED:
      return "Supprimée"
    case OriginRecordStatus.UNKNOWN:
      return "Inconnu"
    default:
      return ""
  }
}

export function originRecordStatusToColor(status: OriginRecordStatus): string {
  switch (status) {
    case OriginRecordStatus.EMPTY:
      return ""
    case OriginRecordStatus.UP_TO_DATE:
      return "#009069"
    case OriginRecordStatus.OUTDATED:
      return "#E65100"
    case OriginRecordStatus.DELETED:
      return "#F00C0C"
    case OriginRecordStatus.UNKNOWN:
      return "#06B8F1"
    default:
      return ""
  }
}
